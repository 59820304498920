body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pdf-navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: bottom;
  top: 0;
  right: 0;
  padding: 10px;
  width: 100%;
}

.pdf-navigation-button {
  margin: 0 5px;
}

.pdf-page-counter {
  text-align: center;
  margin: 10px 0;
}
.pdf-download-button-top {
  margin-right: 50px;
}
#cls-button {
  font-size:18px;
  color: red;
  margin-top: 0px;
  right:10px;
  position: absolute;
 
  text-align: center;
 
}
 